import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/users/user.model';
import { QrDataCardComponent } from '@modules/shared/components/qr-data-card/qr-data-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/shared/notification.service';
import { QRCodeModule } from 'angularx-qrcode';
import { environment as env } from '@environments/environment';
import { UserService } from '@services/auth/user.service';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';

@Component({
  selector: 'app-referral-invite',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    QRCodeModule,
    UserAvatarComponent,
    QrDataCardComponent,
  ],
  providers: [UserService, NotificationService],
  templateUrl: './referral-invite.dialog.html',
  styleUrl: './referral-invite.dialog.scss',
})
export class ReferralInviteDialog {
  loggedUser?: User;

  referredUsers?: User[];

  isReferredUsersView = false;

  constructor(
    public dialogRef: MatDialogRef<ReferralInviteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.loggedUser = data.loggedUser;

      if (this.loggedUser?.id) {
        this.userService
          .getReferredUsers(this.loggedUser.id)
          .subscribe((referredUsers) => {
            this.referredUsers = referredUsers;
          });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  shareInvite() {
    if (!this.loggedUser?.referralCode) {
      return;
    }

    window.navigator.share({
      url: this.loggedUser.getReferralLink(),
    });
  }

  getHeaderText() {
    return `${this.translateService.instant(
      'APP.REFERRAL_INVITE.INVITE_FROM',
    )} ${this.loggedUser?.name}`;
  }

  copyCode() {
    if (!this.loggedUser?.referralCode) {
      return;
    }
    this.clipboard.copy(this.loggedUser?.referralCode);

    this.notificationService.success(
      this.translateService.instant(
        'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
      ),
    );
  }

  setReferredUsersView(isReferredUsersView: boolean) {
    this.isReferredUsersView = isReferredUsersView;
  }

  getQRLogo() {
    switch (env.platformShort) {
      case 'partypage':
        return './assets/pp_logo_qr.png';
      case 'weddingpage':
        return './assets/wp_logo_qr.png';
      default:
        return './assets/ep_logo_qr.png';
    }
  }

  protected env = env;
}
