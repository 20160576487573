import { MenuRoute } from '@models/sidenav/menu-route.model';
import { AppRoutes } from './routes';

export const publicRoutes = [
  `/${AppRoutes.Root.signUp}`,
  `/${AppRoutes.Root.login}`,
  `/${AppRoutes.Root.forgotPassword}`,
  `/${AppRoutes.Root.resetPassword}`,
  `/${AppRoutes.Root.success}`,
  `/${AppRoutes.Events.createEvent}`,
  `
  /${AppRoutes.Events.mizunoTermsOfUse}`,
];

export const ADMIN_NAV_ROUTES: MenuRoute[] = [
  {
    name: 'Revenue',
    route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.revenue}`,
    iconClasses: 'fal fa-wallet',
  },
  {
    name: 'Users',
    route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.Users.root}`,
    iconClasses: 'fal fa-users',
  },
  {
    name: 'Events',
    route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.Events.root}`,
    iconClasses: 'fal fa-stars',
  },
  {
    name: 'Locations',
    route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.locations}`,
    iconClasses: 'fal fa-house',
  },
  {
    name: 'Campaigns',
    route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.Campaigns.root}`,
    iconClasses: 'fal fa-bullhorn',
  },
  {
    name: 'Design Templates',
    iconClasses: 'fal fa-palette',
    children: [
      {
        name: 'Statistics',
        route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.DesignTemplates.designTemplateStatistics}`,
        iconClasses: 'fal fa-ad',
      },
      {
        name: 'Template list',
        route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.DesignTemplates.designTemplates}`,
        iconClasses: 'fal fa-ad',
      },
    ],
  },
  {
    name: 'Affiliates',
    iconClasses: 'fal fa-ad',
    children: [
      {
        name: 'Statistics',
        route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.Affiliates.affiliateStatistics}`,
        iconClasses: 'fal fa-ad',
      },
      {
        name: 'Product list',
        route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.Affiliates.affiliates}`,
        iconClasses: 'fal fa-ad',
      },
    ],
  },
  {
    name: 'Data Set',
    route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.dataSet}`,
    iconClasses: 'fal fa-database',
    children: [
      {
        name: 'Template Assets',
        route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.TemplateAssets.templateAssets}`,
        iconClasses: 'fal fa-images',
      },
      {
        name: 'Template Frames',
        route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.TemplateAssets.templateFrames}`,
        iconClasses: 'fal fa-images',
      },
    ],
  },
  {
    name: 'Prompts',
    route: `/${AppRoutes.Admin.root}/${AppRoutes.Admin.prompts}`,
    iconClasses: 'fal fa-terminal',
  },
];

export const CUSTOMER_NAV_ROUTES: MenuRoute[] = [
  {
    name: 'Dashboard',
    route: `/${AppRoutes.Customer.dashboard}`,
    iconClasses: 'fal fa-house',
  },
  /*   {
    name: 'Events',
    route: `/${AppRoutes.Customer.Events.events}`,
    iconClasses: 'fal fa-stars',
  },
  {
    name: 'Guests',
    route: `/${AppRoutes.Customer.guests}`,
    iconClasses: 'fal fa-users',
  },
  {
    name: 'Templates',
    route: `/${AppRoutes.Customer.templates}`,
    iconClasses: 'fal fa-palette',
  }, */
  {
    name: 'Templates',
    route: `/${AppRoutes.DesignTemplates.designTemplates}`,
    iconClasses: 'fal fa-palette',
  },
];

export const B2B_CUSTOMER_NAV_ROUTES: MenuRoute[] = [
  ...CUSTOMER_NAV_ROUTES,
  {
    name: 'Spaces',
    route: `/${AppRoutes.Customer.Spaces.spaces}`,
    iconClasses: 'fal fa-cube',
  },
  {
    name: 'Communities',
    route: `/${AppRoutes.Customer.communitiesOverview}`,
    iconClasses: 'fal fa-users',
  },
  {
    name: 'Landingpages',
    route: `/${AppRoutes.Customer.LandingPages.landingPages}`,
    iconClasses: 'fal fa-browser',
  },
];

export const WEDDINGPAGE_B2B_CUSTOMER_NAV_ROUTES: MenuRoute[] = [
  ...CUSTOMER_NAV_ROUTES,
];

class MatRoute {}

export const COMMUNITY_ROUTES: MenuRoute[] = [
  {
    name: 'Journeys',
    route: `/events`,
    iconClasses: 'fal fa-stars',
  },
  {
    name: 'Profile',
    route: `/profile`,
    iconClasses: 'far fa-user-circle',
  },
];
