import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavService } from '@services/shared/sidenav.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { environment as env } from '@environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-internal-header',
  standalone: true,
  imports: [CommonModule],
  providers: [],
  templateUrl: './internal-header.component.html',
  styleUrl: './internal-header.component.scss',
})
export class InternalHeaderComponent {
  isDesktop?: boolean;
  navbarDisplayed = true;
  lastTopPosition = 0;
  private topBuffer = 20;

  constructor(
    private sidenavService: SidenavService,
    private screenWidthService: ScreenWidthService,
  ) {
    this.trackIsDesktopChange();
  }

  toggleSidenav() {
    this.sidenavService.toggle().then();
  }

  private trackIsDesktopChange() {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (!this.isDesktop) {
      const isScrollUp = this.isScrollUp();

      if (
        (this.navbarDisplayed && !isScrollUp) ||
        (!this.navbarDisplayed && isScrollUp)
      ) {
        // this.globalCommunicationService.navbarDisplayChanged(isScrollUp);
      }

      this.navbarDisplayed = isScrollUp;
    }

    this.lastTopPosition = window.scrollY;
  }

  private isScrollUp() {
    return (
      this.lastTopPosition <= this.topBuffer ||
      this.lastTopPosition > window.scrollY
    );
  }

  get userTheme(): string {
    const userTheme = localStorage.getItem('userTheme');

    if (userTheme) {
      return userTheme;
    }

    return 'light';
  }

  protected env = env;
}
