<div
  class="internal-menu-container"
  [class.wedding-page]="env.platformShort === 'weddingpage'"
  [class.expand]="shouldExpandMenuContainer"
  [class.customer-view]="isCustomerView"
>
  <div class="internal-menu-inner-cont">
    <a class="header-link">
      @if (selectedCommunity) {
        <img
          class="community-logo"
          [src]="selectedCommunity.logoUrl"
          alt="Community Logo"
        />
      } @else {
        <img
          class="header-logo"
          [src]="userTheme === 'dark' ? env.iconDarkAsset : env.iconAsset"
          alt="eventpage.ai Logo"
        />
      }

      @if (isOverMode) {
        <i class="fal fa-times" (click)="toggleSidenav()"></i>
      }
    </a>

    @for (routeItem of routes; track routeItem.name) {
      @if (!routeItem.children?.length) {
        <a
          [routerLink]="
            selectedCommunity && !isOwnerView
              ? '/community/' + routeItem.route
              : routeItem.route
          "
          (click)="onRouteClick()"
          class="menu-item"
          [class.active]="isRouteActive(routeItem.route)"
          matTooltip="{{ routeItem.name }}"
          matTooltipPosition="right"
          matTooltipClass="menu-item-tooltip"
          [matTooltipDisabled]="!isCustomerView"
        >
          @if (routeItem.iconClasses) {
            <i [class]="routeItem.iconClasses"></i>
          }

          @if (!isCustomerView) {
            <span class="menu-item-label"
              >{{ routeItem.name }}
              @if (routeItem.subtitle) {
                <span class="menu-item-label-small">{{
                  routeItem.subtitle
                }}</span>
              }
            </span>
          }
        </a>
      } @else {
        <a
          (click)="onParentRouteClick(routeItem)"
          class="menu-item parent-menu-item"
          [class.parent-opened]="routeItem.opened"
        >
          @if (routeItem.iconClasses) {
            <i [class]="routeItem.iconClasses"></i>
          }

          <span class="menu-item-label"
            >{{ routeItem.name }}
            @if (routeItem.subtitle) {
              <span class="menu-item-label-small">{{
                routeItem.subtitle
              }}</span>
            }
          </span>

          <i
            class="fal fa-angle-{{ routeItem.opened ? 'up' : 'down' }} toggle"
          ></i>
        </a>

        @if (routeItem.opened) {
          @for (
            childRouteItem of routeItem.children;
            track childRouteItem.name
          ) {
            <a
              [routerLink]="childRouteItem.route"
              (click)="onRouteClick()"
              class="menu-item child-menu-item"
              [class.active]="childRouteItem.route === activeRouteUrl"
            >
              <span class="menu-item-label"
                >{{ childRouteItem.name }}
                @if (childRouteItem.subtitle) {
                  <span class="menu-item-label-small">{{
                    childRouteItem.subtitle
                  }}</span>
                }
              </span>
            </a>
          }
        }
      }
    }
  </div>

  <div class="user-actions">
    @if (!selectedCommunity) {
      <i
        class="far fa-ticket referral-icon"
        (click)="onOpenReferralInviteDialog()"
      ></i>
    }

    @if (selectedCommunity && !isOwnerView) {
      <app-internal-sidenav-user-icon
        [menuData]="getCommunityMemberData()"
        [themeModeAvailable]="!selectedCommunity.hasPresetThemeMode()"
      ></app-internal-sidenav-user-icon>
    } @else {
      <app-internal-sidenav-user-icon></app-internal-sidenav-user-icon>
    }
  </div>
</div>
