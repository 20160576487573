import { Routes } from '@angular/router';
import { SsoSuccessPage } from '@modules/auth/pages/sso-success/sso-success.page';
import { CommunityMembersManagementComponent } from '@modules/communities/components/community-members-management/community-members-management.component';
import { HubspotCallbackPage } from '@modules/integrations/hubspot/pages/callback/hubspot-callback.page';
import { AppRoutes } from './routes';
import { LoginPage } from '@modules/auth/pages/login/login.page';
import { SignUpPage } from '@modules/sign-up/pages/sign-up/sign-up.page';
import { unauthenticatedOnlyGuard } from './auth/unauthenticated-only.guard';
import { ResetPasswordPage } from '@modules/auth/pages/reset-password/reset-password.page';
import { authRoleGuard } from './auth/auth-role.guard';
import { UserRole } from '@models/users/user-role.enum';
import { AffiliateProductListPage } from '@modules/admin/affiliates/pages/affiliate-product-list/affiliate-product-list.page';
import { AffiliateProductStatsPage } from '@modules/admin/affiliates/pages/affiliate-product-stats/affiliate-product-stats.page';
import { AffiliateProductDetailsPage } from '@modules/admin/affiliates/pages/affiliate-product-details/affiliate-product-details.page';
import { DesignTemplateListPage } from '@modules/admin/design-templates/pages/design-template-list/design-template-list.page';
import { DesignTemplateDetailsPage } from '@modules/admin/design-templates/pages/design-template-details/design-template-details.page';
import { DesignTemplateStatsPage } from '@modules/admin/design-templates/pages/design-template-stats/design-template-stats.page';
import { CampaignListPage } from '@modules/admin/campaigns/pages/campaign-list/campaign-list.page';
import { CampaignDetailsPage } from '@modules/admin/campaigns/pages/campaign-details/campaign-details.page';
import { UserListPage } from '@modules/admin/users/pages/user-list/user-list.page';
import { EventListPage } from '@modules/admin/events/pages/event-list/event-list.page';

export const getDynamicRoutes = () => {
  let host = null;

  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname
  ) {
    host = window.location.hostname;
  }

  if (host && host === 'peranhalter.com') {
    const uri = 'per-anhalter';

    let redirectPath = AppRoutes.Customer.community;

    // Dynamically update routes
    const newRoutes = deepCopyRoutes(routes);

    const rootRouteIdx = newRoutes.findIndex((route) => route.path === '');
    if (rootRouteIdx !== -1) {
      newRoutes[rootRouteIdx].redirectTo = redirectPath;
    }

    const singUpRouteIdx = newRoutes.findIndex(
      (route) => route.path === AppRoutes.Root.signUp,
    );
    if (singUpRouteIdx !== -1) {
      newRoutes[singUpRouteIdx].component = undefined;
      newRoutes[singUpRouteIdx].redirectTo = `communities/${uri}/apply`;
    }
    const loginRouteIdx = newRoutes.findIndex(
      (route) => route.path === AppRoutes.Root.login,
    );
    if (loginRouteIdx !== -1) {
      newRoutes[loginRouteIdx].component = undefined;
      newRoutes[loginRouteIdx].canActivate = undefined;
      newRoutes[loginRouteIdx].redirectTo = `communities/${uri}/login`;
    }

    const wildcardRouteIdx = newRoutes.findIndex(
      (route) => route.path === '**',
    );
    if (wildcardRouteIdx !== -1) {
      newRoutes[wildcardRouteIdx].redirectTo = AppRoutes.Customer.community;
    }

    return newRoutes;
  } else {
    return routes;
  }
};

export const routes: Routes = [
  {
    path: 'members',
    component: CommunityMembersManagementComponent,
    data: { breadcrumb: 'members' },
  },
  {
    path: AppRoutes.Root.signUp,
    component: SignUpPage,
    data: { breadcrumb: 'sign-up' },
  },
  {
    path: AppRoutes.Root.login,
    component: LoginPage,
    data: { breadcrumb: 'login' },
    canActivate: [unauthenticatedOnlyGuard],
  },
  {
    path: AppRoutes.Root.forgotPassword,
    loadComponent: () =>
      import('@modules/auth/pages/forgot-password/forgot-password.page').then(
        (mod) => mod.ForgotPasswordPage,
      ),
    data: { breadcrumb: 'forgot-password' },
  },
  {
    path: AppRoutes.Root.resetPassword,
    loadChildren: () => resetPasswordRoutes,
    data: { breadcrumb: 'reset-password' },
  },
  {
    path: AppRoutes.Root.ssoSuccess,
    component: SsoSuccessPage,
    canActivate: [unauthenticatedOnlyGuard],
    data: { breadcrumb: 'sso-success' },
  },
  {
    path: AppRoutes.Root.hubspotCallback,
    component: HubspotCallbackPage,
    data: { breadcrumb: 'hubspot/oauth/callback' },
  },
  {
    path: AppRoutes.Customer.welcome,
    loadComponent: () =>
      import('@modules/customer/pages/welcome/welcome.page').then(
        (mod) => mod.WelcomePage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'welcome', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.dashboard,
    loadComponent: () =>
      import('@modules/customer/pages/dashboard/dashboard.page').then(
        (mod) => mod.DashboardPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'dashboard', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.eventLink,
    loadComponent: () =>
      import('@modules/event-link/pages/event-link/event-link.page').then(
        (mod) => mod.EventLinkPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'eventlink.bio', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.eventLinkUrl,
    loadComponent: () =>
      import('@modules/event-link/pages/event-link/event-link.page').then(
        (mod) => mod.EventLinkPage,
      ),
    data: { breadcrumb: 'eventlink.bio/url' },
  },
  {
    path: AppRoutes.Customer.embeddedSection,
    loadComponent: () =>
      import(
        '@modules/embedded-section/pages/embedded-section/embedded-section.page'
      ).then((mod) => mod.EmbeddedSectionPage),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'embedded-section', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.embeddedSectionUrl,
    loadComponent: () =>
      import(
        '@modules/embedded-section/components/embedded-section/embedded-section.component'
      ).then((mod) => mod.EmbeddedSectionComponent),
    data: { breadcrumb: 'embedded-section/url' },
  },
  {
    path: AppRoutes.Customer.LandingPages.landingPages,
    loadComponent: () =>
      import(
        '@modules/landingpages/pages/landingpages-overview/landingpages-overview.page'
      ).then((mod) => mod.LandingpagesOverviewPage),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'landingpages', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.communityProfile,
    loadComponent: () =>
      import(
        '@modules/communities/components/community-profile/community-profile.component'
      ).then((mod) => mod.CommunityProfileComponent),
    data: { breadcrumb: 'community/profile' },
  },
  {
    path: AppRoutes.Customer.communityEvents,
    loadComponent: () =>
      import(
        '@modules/communities/pages/community-overview/community-overview.page'
      ).then((mod) => mod.CommunityOverviewPage),
    data: { breadcrumb: 'community/events' },
  },
  {
    path: AppRoutes.Customer.community,
    loadComponent: () =>
      import(
        '@modules/communities/pages/community-overview/community-overview.page'
      ).then((mod) => mod.CommunityOverviewPage),
    data: { breadcrumb: 'community' },
  },
  {
    path: AppRoutes.Customer.communities,
    loadComponent: () =>
      import(
        '@modules/communities/pages/communities-landing/communities-landing.page'
      ).then((mod) => mod.CommunitiesLandingPage),
    data: { breadcrumb: 'communities/url' },
  },
  {
    path: AppRoutes.Customer.communityManagement,
    loadComponent: () =>
      import('@modules/communities/pages/community/community.page').then(
        (mod) => mod.CommunityPage,
      ),
    data: { breadcrumb: 'communities/url/manage' },
  },
  {
    path: AppRoutes.Customer.communitiesUrlAction,
    loadComponent: () =>
      import(
        '@modules/communities/pages/communities-landing/communities-landing.page'
      ).then((mod) => mod.CommunitiesLandingPage),
    data: { breadcrumb: 'communities/url/action' },
  },
  {
    path: AppRoutes.Customer.settings,
    loadComponent: () =>
      import('@modules/customer/pages/settings/settings.page').then(
        (mod) => mod.SettingsPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'settings', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.profile,
    loadComponent: () =>
      import('@modules/customer/pages/profile/profile.page').then(
        (mod) => mod.ProfilePage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'profile', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.domainsAndSender,
    loadComponent: () =>
      import(
        '@modules/customer/pages/domains-and-sender/domains-and-sender.page'
      ).then((mod) => mod.DomainsAndSenderPage),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'settings', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.communitiesOverview,
    loadComponent: () =>
      import('@modules/communities/pages/communities/communities.page').then(
        (mod) => mod.CommunitiesPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'communities', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Admin.root,
    loadChildren: () => adminRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Events.create,
    redirectTo: AppRoutes.Events.createEvent,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.Events.createEvent,
    loadComponent: () =>
      import(
        '@modules/events/create-event/pages/create-event/create-event.page'
      ).then((mod) => mod.CreateEventPage),
    data: { breadcrumb: 'create-event' },
  },
  {
    path: AppRoutes.Events.eventPayment,
    loadComponent: () =>
      import(
        '@modules/events/components/event-payment/event-payment.component'
      ).then((mod) => mod.EventPaymentComponent),
    data: { breadcrumb: 'event-payment' },
  },
  {
    path: AppRoutes.Events.createCampainEvent,
    loadComponent: () =>
      import(
        '@modules/events/create-wg-party/pages/create-campaign-party-page/create-campaign-party-page.component'
      ).then((mod) => mod.CreateCampaignPartyPageComponent),
    data: { breadcrumb: 'create-event/campaign' },
  },
  {
    path: AppRoutes.Events.eventByUri,
    loadComponent: () =>
      import('@modules/events/pages/event/event.page').then(
        (mod) => mod.EventPage,
      ),
    data: { breadcrumb: 'event' },
  },
  {
    // Helper route used by meta pixel tracking
    path: AppRoutes.Events.eventByUriPublished,
    loadComponent: () =>
      import('@modules/events/pages/event/event.page').then(
        (mod) => mod.EventPage,
      ),
    data: { breadcrumb: 'event' },
  },
  {
    path: AppRoutes.Events.eventSellTickets,
    loadComponent: () =>
      import('@modules/events/pages/event/event.page').then(
        (mod) => mod.EventPage,
      ),
    data: { breadcrumb: 'sell-tickets' },
  },
  {
    path: AppRoutes.LandingPage.landingPage,
    loadComponent: () =>
      import('@modules/events/pages/event/event.page').then(
        (mod) => mod.EventPage,
      ),
    data: { breadcrumb: 'landing' },
  },
  {
    path: AppRoutes.Events.campaignEventVote,
    loadComponent: () =>
      import(
        '@modules/events/pages/campaign-event-voting/campaign-event-voting.page'
      ).then((mod) => mod.CampaignEventVotingPage),
    data: { breadcrumb: 'event' },
  },
  {
    path: AppRoutes.Events.mizunoTermsOfUse,
    loadComponent: () =>
      import(
        '@modules/events/pages/terms-of-use/mizuno-terms-of-use/mizuno-terms-of-use.component'
      ).then((mod) => mod.MizunoTermsOfUseComponent),
    data: { breadcrumb: 'mizuno-terms-of-use' },
  },
  {
    path: AppRoutes.Events.underarmourTermsOfUse,
    loadComponent: () =>
      import(
        '@modules/events/pages/terms-of-use/ua-terms-of-use/ua-terms-of-use.component'
      ).then((mod) => mod.UaTermsOfUseComponent),
    data: { breadcrumb: 'underarmour-terms-of-use' },
  },
  {
    path: AppRoutes.DesignTemplates.designTemplatePreview,
    loadComponent: () =>
      import(
        '@modules/design-templates/pages/design-template-preview/design-template-preview.page'
      ).then((mod) => mod.DesignTemplatePreviewPage),
    data: { breadcrumb: 'design-template-preview' },
  },
  {
    path: AppRoutes.DesignTemplates.designTemplates,
    loadComponent: () =>
      import(
        '@modules/design-templates/pages/templates-overview/templates-overview.page'
      ).then((mod) => mod.TemplatesOverviewPage),
    data: { breadcrumb: 'templates-overview' },
  },
  {
    path: AppRoutes.Customer.Spaces.spaces,
    loadComponent: () =>
      import('@modules/spaces/pages/spaces-overview/spaces-overview.page').then(
        (mod) => mod.SpacesOverviewPage,
      ),
    data: { breadcrumb: 'spaces' },
  },
  {
    path: AppRoutes.Customer.Spaces.space,
    loadComponent: () =>
      import('@modules/spaces/pages/space/space.page').then(
        (mod) => mod.SpacePage,
      ),
    data: { breadcrumb: 'spaces/uri' },
  },
  {
    path: AppRoutes.Customer.Spaces.spaceBookings,
    loadComponent: () =>
      import('@modules/spaces/pages/space/space.page').then(
        (mod) => mod.SpacePage,
      ),
    data: { breadcrumb: 'spaces/uri/bookings' },
  },
  {
    path: AppRoutes.Customer.Spaces.spaceBooking,
    loadComponent: () =>
      import('@modules/spaces/pages/space-booking/space-booking.page').then(
        (mod) => mod.SpaceBookingPage,
      ),
    data: { breadcrumb: 'space-bookings/uuid' },
  },
  {
    path: AppRoutes.Customer.Payments.stripeCheckout,
    loadComponent: () =>
      import(
        '@modules/payments/pages/stripe-checkout/stripe-checkout.page'
      ).then((mod) => mod.StripeCheckoutPage),
    data: { breadcrumb: 'stripe-checkout' },
  },
  {
    path: AppRoutes.Customer.Payments.paymentReturn,
    loadComponent: () =>
      import('@modules/payments/pages/payment-return/payment-return.page').then(
        (mod) => mod.PaymentReturnPage,
      ),
    data: { breadcrumb: 'payment-return' },
  },
  {
    path: AppRoutes.Customer.Payments.paymentSuccessful,
    loadComponent: () =>
      import(
        '@modules/payments/pages/payment-successful/payment-successful.page'
      ).then((mod) => mod.PaymentSuccessfulPage),
    data: { breadcrumb: 'payment-successful' },
  },
  { path: '', pathMatch: 'full', redirectTo: AppRoutes.Root.login },
  { path: '**', redirectTo: AppRoutes.Root.login },
];

const resetPasswordRoutes: Routes = [
  { path: '', component: ResetPasswordPage },
  { path: AppRoutes.Root.success, component: ResetPasswordPage },
];

const adminRoutes: Routes = [
  {
    path: '',
    component: AffiliateProductListPage,
  },
  {
    path: AppRoutes.Admin.Affiliates.affiliates,
    loadChildren: () => affiliateRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.Affiliates.affiliateStatistics,
    component: AffiliateProductStatsPage,
  },
  {
    path: AppRoutes.Admin.DesignTemplates.designTemplates,
    loadChildren: () => designTemplatesRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.DesignTemplates.designTemplateStatistics,
    component: DesignTemplateStatsPage,
  },
  {
    path: AppRoutes.Admin.Users.root,
    loadChildren: () => usersRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.Events.root,
    loadChildren: () => eventsRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.Campaigns.root,
    loadChildren: () => campaignsRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.TemplateAssets.templateAssets,
    loadComponent: () =>
      import(
        '@modules/admin/template-assets/pages/template-asset-list/template-asset-list.page'
      ).then((mod) => mod.TemplateAssetListPage),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.TemplateAssets.templateFrames,
    loadComponent: () =>
      import(
        '@modules/admin/template-assets/pages/template-frame-list/template-frame-list.page'
      ).then((mod) => mod.TemplateFrameListPage),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.prompts,
    loadComponent: () =>
      import('@modules/admin/prompts/pages/prompt-list/prompt-list.page').then(
        (mod) => mod.PromptListPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
];

const affiliateRoutes: Routes = [
  {
    path: '',
    component: AffiliateProductListPage,
  },
  {
    path: ':id',
    component: AffiliateProductDetailsPage,
  },
];

const designTemplatesRoutes: Routes = [
  {
    path: '',
    component: DesignTemplateListPage,
  },
  {
    path: ':id',
    component: DesignTemplateDetailsPage,
  },
];

const usersRoutes: Routes = [
  {
    path: '',
    component: UserListPage,
  },
];

const eventsRoutes: Routes = [
  {
    path: '',
    component: EventListPage,
  },
];

const campaignsRoutes: Routes = [
  {
    path: '',
    component: CampaignListPage,
  },
  {
    path: ':uri',
    component: CampaignDetailsPage,
  },
];

export function deepCopyRoutes(routes: Routes): Routes {
  return routes.map((route) => ({
    ...route,
    data: route.data ? { ...route.data } : undefined,
    canActivate: route.canActivate ? [...route.canActivate] : undefined,
    children: route.children ? deepCopyRoutes(route.children) : undefined,
    loadComponent: route.loadComponent ? route.loadComponent : undefined,
    loadChildren: route.loadChildren ? route.loadChildren : undefined,
  }));
}
