import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Asset, createDataUrl, isAssetEmpty } from '@models/shared/asset.model';
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { FontService } from '@services/shared/font.service';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';

export enum InvitationCardPreviewTab {
  FRONT_SIDE = 'FRONT_SIDE',
  BACK_SIDE = 'BACK_SIDE',
}

@Component({
  selector: 'app-invitation-card-preview',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './invitation-card-preview.component.html',
  styleUrl: './invitation-card-preview.component.scss',
})
export class InvitationCardPreviewComponent implements OnInit {
  @Input() form!: FormGroup;

  visualForm!: FormGroup;

  InvitationCardPreviewTab = InvitationCardPreviewTab;
  createDataUrl = createDataUrl;

  selectedTab = InvitationCardPreviewTab.FRONT_SIDE;

  constructor(private fontService: FontService) {}

  ngOnInit(): void {
    combineLatest([
      this.form.controls['primaryLogoFont'].valueChanges,
      this.form.controls['bodyFont'].valueChanges,
    ]).subscribe(([primaryLogoFont, bodyFont]) => {
      this.fontService.updateFonts([primaryLogoFont, bodyFont]);
    });

    this.visualForm = this.form.get('visualForm') as FormGroup;
  }

  changeTab(tab: InvitationCardPreviewTab) {
    this.selectedTab = tab;
  }

  get formValue() {
    return this.form.value;
  }

  get visualFormValue() {
    return this.visualForm.value;
  }

  getAsset(assetName: string): Asset {
    return this.visualForm.controls[assetName].value;
  }

  getTemplateAsset(assetName: string): EventTemplateAsset {
    return this.visualForm.controls[assetName].value;
  }

  protected readonly isAssetEmpty = isAssetEmpty;
}
