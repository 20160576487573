import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@models/users/user.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthGoogleService } from '@services/auth/auth-google.service';
import { AuthService } from '@services/auth/auth.service';
import { NotificationService } from '@services/shared/notification.service';
import { OAuthService } from 'angular-oauth2-oidc';
import moment from 'moment/moment';
import { AppRoutes } from '../../../../routes';

@Component({
  selector: 'app-sso-success',
  standalone: true,
  imports: [TranslateModule],
  providers: [NotificationService],
  templateUrl: './sso-success.page.html',
  styleUrl: './sso-success.page.scss',
})
export class SsoSuccessPage implements OnInit {
  inProgress = false;

  constructor(
    private authGoogleService: AuthGoogleService,
    private authService: AuthService,
    private oAuthService: OAuthService,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.tryLogin();
  }

  tryLogin() {
    const fragment = window.location.hash;
    const urlParams = new URLSearchParams(fragment.replace('#', '?'));
    if (urlParams.has('error') && urlParams.get('error') === 'access_denied') {
      const encodedState = urlParams.get('state') || '';
      const redirectUri = this.extractLoginErrorState(encodedState);
      this.handleLoginError(redirectUri);
      return;
    }

    this.oAuthService.tryLogin().then(() => {
      const hasValidIdToken = this.oAuthService.hasValidIdToken();
      const state = this.oAuthService.state;
      if (hasValidIdToken && state?.length) {
        this.loginOrRegister(state);
      }
    });
  }

  loginOrRegister(redirectUri: string) {
    const idToken = this.authGoogleService.getIdToken();
    if (idToken) {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;
      this.authService.loginWithSSOIdToken(
        idToken,
        false,
        (user: User) => {
          this.inProgress = false;

          if (
            user &&
            moment(user.createdAt).isBetween(
              moment().subtract(1, 'minutes'),
              moment(),
            )
          ) {
            this.router.navigate(['/', AppRoutes.Customer.welcome]);
          } else {
            this.router.navigate([redirectUri]);
          }
        },
        () => {
          this.inProgress = false;

          this.notificationService.error(
            this.translateService.instant('APP.AUTH.ERRORS.SSO'),
          );
          this.logoutAndRedirect(redirectUri);
        },
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.AUTH.ERRORS.SSO'),
      );
      this.logoutAndRedirect(redirectUri);
    }
  }

  extractLoginErrorState(encodedState: string): string {
    const parts = encodedState.split(';');
    const encodedUri = parts.length > 1 ? parts[1] : parts[0];
    return decodeURIComponent(decodeURIComponent(encodedUri));
  }

  handleLoginError(redirectUri: string) {
    this.translateService
      .get('APP.AUTH.ERRORS.SSO')
      .subscribe((msg) => this.notificationService.error(msg));
    redirectUri = redirectUri.length ? redirectUri : '/login';
    this.logoutAndRedirect(redirectUri);
  }

  logoutAndRedirect(redirectUri: string) {
    this.authGoogleService.logout();
    this.router.navigate([redirectUri]);
  }
}
