import {
  Component,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { InternalSidenavUserIconComponent } from '../internal-sidenav-user-icon/internal-sidenav-user-icon.component';
import { MenuRoute } from '@models/sidenav/menu-route.model';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { environment as env } from '@environments/environment';
import { Community } from '@models/communities/community.model';
import { User } from '@models/users/user.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-internal-customer-header',
  standalone: true,
  imports: [CommonModule, RouterModule, InternalSidenavUserIconComponent],
  templateUrl: './internal-customer-header.component.html',
  styleUrl: './internal-customer-header.component.scss',
})
export class InternalCustomerHeaderComponent {
  private _routes: MenuRoute[] = [];
  private _selectedCommunity?: Community;

  @Input() loggedUser?: User;

  @Input() set routes(routes: MenuRoute[]) {
    if (!!routes && routes.length > 0) {
      this._routes = routes;
      this.checkRouteItemOpened();
    }
  }

  get routes(): MenuRoute[] {
    return this._routes;
  }

  @Input() isLowerZIndexPage = false;

  @Input() set selectedCommunity(selectedCommunity: Community | undefined) {
    this._selectedCommunity = selectedCommunity;
    if (this.selectedCommunity) {
      this.checkLoggedUserCommunity();
    }
  }

  get selectedCommunity(): Community | undefined {
    return this._selectedCommunity;
  }

  @Output() openReferralInviteDialog = new EventEmitter<void>();

  activeRouteUrl?: string;
  shouldExpandMenuContainer?: boolean;

  isDesktop?: boolean;
  navbarDisplayed = true;
  lastTopPosition = 0;
  private topBuffer = 20;

  sidenavOpen = false;

  isOwnerView = false;

  constructor(
    private router: Router,
    private screenWidthService: ScreenWidthService,
  ) {
    this.trackIsDesktopChange();
    this.trackRouteChanges();
  }

  ngOnInit(): void {
    this.checkLoggedUserCommunity();
  }

  checkLoggedUserCommunity() {
    if (this.loggedUser) {
      this.isOwnerView = this.loggedUser.id === this.selectedCommunity?.ownerId;
    }
  }

  toggleSidenav() {
    this.sidenavOpen = !this.sidenavOpen;
  }

  private trackIsDesktopChange() {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });
  }

  private trackRouteChanges() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRouteUrl = window.location.pathname;
        this.checkRouteItemOpened();
      }
    });
  }

  onRouteClick() {
    this.closeSidenav();
  }

  onParentRouteClick(routeItem: MenuRoute) {
    routeItem.opened = !routeItem.opened;
  }

  closeSidenav() {
    this.sidenavOpen = false;
  }

  checkRouteItemOpened() {
    for (const route of this.routes) {
      if (route.children) {
        route.opened = this.isRouteItemOpened(route.children);
      }
    }
  }

  isRouteItemOpened(routes: MenuRoute[]): boolean {
    for (const route of routes) {
      if (this.activeRouteUrl === route.route) {
        return true;
      }
    }
    return false;
  }

  isRouteActive(routeItem: any): boolean {
    if (this.activeRouteUrl) {
      if (this.selectedCommunity) {
        routeItem =
          routeItem === '/' ? '/community/' : '/community' + routeItem;
        if (routeItem.endsWith('/')) {
          routeItem = routeItem.slice(0, routeItem.length - 1);
        }
        if (routeItem === this.activeRouteUrl) {
          return true;
        }
        if (routeItem.length !== this.activeRouteUrl.length) {
          return false;
        }
      }

      if (routeItem === this.activeRouteUrl) {
        return true;
      }
      const routeSegments = routeItem.split('/');
      const activeRouteSegments = this.activeRouteUrl.split('/');
      for (let i = 0; i < routeSegments.length; i++) {
        const routeSegment = routeSegments[i];
        const activeRouteSegment = activeRouteSegments[i];

        if (
          routeSegment.startsWith(':') ||
          routeSegment === activeRouteSegment
        ) {
          continue;
        }
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  onOpenReferralInviteDialog() {
    this.openReferralInviteDialog.emit();
  }

  getCommunityMemberData() {
    return {
      headerText: '',
      initials: this.loggedUser?.getInitials() || '',
      items: [
        {
          event: 'LOGOUT',
          label: 'APP.LOGOUT',
          icon: 'fa-sign-out-alt',
        },
      ],
    };
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (!this.isDesktop) {
      const isScrollUp = this.isScrollUp();

      if (
        (this.navbarDisplayed && !isScrollUp) ||
        (!this.navbarDisplayed && isScrollUp)
      ) {
        // this.globalCommunicationService.navbarDisplayChanged(isScrollUp);
      }

      this.navbarDisplayed = isScrollUp;
    }

    this.lastTopPosition = window.scrollY;
  }

  private isScrollUp() {
    return (
      this.lastTopPosition <= this.topBuffer ||
      this.lastTopPosition > window.scrollY
    );
  }

  get userTheme(): string {
    const userTheme = localStorage.getItem('userTheme');

    if (userTheme) {
      return userTheme;
    }

    return 'light';
  }

  protected env = env;
}
