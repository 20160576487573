import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunityMember } from '@models/communities/community-member.model';
import { Community } from '@models/communities/community.model';
import { User } from '@models/users/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { CommunityService } from '@services/communities/community.service';
import { InternalSidenavUserIconComponent } from '../internal-sidenav-user-icon/internal-sidenav-user-icon.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MenuRoute } from '@models/sidenav/menu-route.model';
import { SidenavService } from '@services/shared/sidenav.service';
import { environment as env } from '@environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-internal-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    InternalSidenavUserIconComponent,
    TranslateModule,
  ],
  providers: [SidenavService, CommunityService],
  templateUrl: './internal-sidenav.component.html',
  styleUrl: './internal-sidenav.component.scss',
})
export class InternalSidenavComponent implements OnInit {
  private _routes: MenuRoute[] = [];
  private _selectedCommunity?: Community;
  showMenu: boolean = false;
  communitiesMemberOf?: CommunityMember[];
  isMemberView: boolean = false;
  isOwnerView = false;
  @Input() set selectedCommunity(selectedCommunity: Community | undefined) {
    this._selectedCommunity = selectedCommunity;
    if (this.selectedCommunity) {
      this.checkLoggedUserCommunity();
    }
  }

  get selectedCommunity(): Community | undefined {
    return this._selectedCommunity;
  }

  @Input() loggedUser?: User;

  @Input() set routes(routes: MenuRoute[]) {
    if (!!routes && routes.length > 0) {
      this._routes = routes;
      this.checkRouteItemOpened();
    }
  }

  get routes(): MenuRoute[] {
    return this._routes;
  }

  @Input() isOverMode?: boolean;

  @Input() isCustomerView?: boolean;

  @Output() openReferralInviteDialog = new EventEmitter<void>();

  activeRouteUrl?: string;
  shouldExpandMenuContainer?: boolean;

  constructor(
    private router: Router,
    private sidenavService: SidenavService,
  ) {
    this.trackRouteChanges();
  }

  ngOnInit(): void {
    this.checkLoggedUserCommunity();
  }

  checkLoggedUserCommunity() {
    if (this.loggedUser) {
      if (this.selectedCommunity)
        this.isMemberView = this.selectedCommunity.isMember(this.loggedUser.id);
      this.communitiesMemberOf = this.loggedUser.communitiesMemberOf;
      this.isOwnerView = this.loggedUser.id === this.selectedCommunity?.ownerId;
    }
  }

  private trackRouteChanges() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRouteUrl = window.location.pathname;
        this.checkRouteItemOpened();
      }
    });
  }
  updateRoutes() {
    this.routes = this.routes.filter((x) => x.name !== 'Community');
    this.router.navigate(['/dashboard']);
  }

  isRouteActive(routeItem: any): boolean {
    if (this.activeRouteUrl) {
      if (this.selectedCommunity && !this.isOwnerView) {
        routeItem =
          routeItem === '/' ? '/community/' : '/community' + routeItem;
        if (routeItem.endsWith('/')) {
          routeItem = routeItem.slice(0, routeItem.length - 1);
        }
        if (routeItem === this.activeRouteUrl) {
          return true;
        }
        if (routeItem.length !== this.activeRouteUrl.length) {
          return false;
        }
      }

      if (routeItem === this.activeRouteUrl) {
        return true;
      }
      const routeSegments = routeItem.split('/');
      const activeRouteSegments = this.activeRouteUrl.split('/');
      for (let i = 0; i < routeSegments.length; i++) {
        const routeSegment = routeSegments[i];
        const activeRouteSegment = activeRouteSegments[i];

        if (
          routeSegment.startsWith(':') ||
          routeSegment === activeRouteSegment
        ) {
          continue;
        }
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  getCommunityMemberData() {
    return {
      headerText: '',
      initials: this.loggedUser?.getInitials() || '',
      items: [
        {
          event: 'LOGOUT',
          label: 'APP.LOGOUT',
          icon: 'fa-sign-out-alt',
        },
      ],
    };
  }

  toggleSidenav() {
    this.sidenavService.toggle().then();
  }

  onRouteClick() {
    if (this.isOverMode) {
      this.closeSidenav();
    }
  }

  onParentRouteClick(routeItem: MenuRoute) {
    if (routeItem.name === 'Community') {
    }
    routeItem.opened = !routeItem.opened;
  }

  closeSidenav() {
    const sidenavCloseRes = this.sidenavService.close();
    sidenavCloseRes?.then();
  }

  checkRouteItemOpened() {
    for (const route of this.routes) {
      if (route.children) {
        route.opened = this.isRouteItemOpened(route.children);
      }
    }
  }

  isRouteItemOpened(routes: MenuRoute[]): boolean {
    for (const route of routes) {
      if (this.activeRouteUrl === route.route) {
        return true;
      }
    }
    return false;
  }

  onOpenReferralInviteDialog() {
    this.openReferralInviteDialog.emit();
  }

  get userTheme(): string {
    const userTheme = localStorage.getItem('userTheme');

    if (userTheme) {
      return userTheme;
    }

    return 'light';
  }

  protected env = env;
}
