import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HubspotRequest } from '@models/integrations/hubspot/dto/hubspot.request';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { HubspotService } from '@services/integrations/hubspot.service';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-hubspot-callback',
  standalone: true,
  imports: [],
  providers: [NotificationService],
  templateUrl: './hubspot-callback.page.html',
  styleUrl: './hubspot-callback.page.scss',
})
export class HubspotCallbackPage implements OnInit {
  authCode: string | null = null;
  redirectUri: string | null = null;

  constructor(
    private hubspotService: HubspotService,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authCode = this.getAuthCodeFromUrl();
    this.redirectUri = this.getRedirectUriFromUrl();

    if (this.authCode) {
      this.connectHubspotAccount(this.authCode);
    } else {
      this.redirectUser();
    }
  }

  connectHubspotAccount(authCode: string): void {
    const req: HubspotRequest = { code: authCode };
    this.hubspotService.connect(req).subscribe({
      next: () => {
        this.notificationService.success(
          this.translateService.instant('APP.INTEGRATIONS.HUBSPOT.SUCCESS'),
        );
        this.authService.refreshTheLoggedUserData();
        this.redirectUser();
      },
      error: () => {
        this.notificationService.error(
          this.translateService.instant('APP.INTEGRATIONS.HUBSPOT.ERROR'),
        );
        this.redirectUser();
      },
    });
  }

  getAuthCodeFromUrl(): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get('code');
  }

  getRedirectUriFromUrl(): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get('state');
  }

  redirectUser(): void {
    if (this.redirectUri) {
      const urlTree = this.router.parseUrl(this.redirectUri);
      const innerRedirectUri = urlTree.queryParams['redirectUri'];
      if (innerRedirectUri) {
        const innerUrlTree = this.router.parseUrl(innerRedirectUri);
        this.router.navigateByUrl(innerUrlTree);
      } else {
        this.router.navigateByUrl(urlTree);
      }
    } else {
      this.router.navigate(['/settings'], { queryParams: { tab: 'connect' } });
    }
  }
}
