import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let fbq: any;

@Injectable({
  providedIn: 'root',
})
export class MetaPixelService {
  private metaPixelId: string = '1048345184000072';
  private isMetaPixelInitialized: boolean = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.trackPageView(event.urlAfterRedirects);
      }
    });
  }

  enableTracking() {
    if (!this.isMetaPixelInitialized) {
      this.addMetaPixel();
    }
  }

  disableTracking() {
    this.removeMetaPixel();
  }

  private addMetaPixel() {
    const script = document.createElement('script');
    script.id = 'meta-pixel-script';

    script.textContent = `
      !function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', '${this.metaPixelId}', { cookieDomain: 'weddingpage.ai' });
      fbq('track', 'PageView');
      `;

    document.head.appendChild(script);

    const noscriptContainer = document.createElement('noscript');
    noscriptContainer.id = 'meta-pixel-noscript';
    const img = document.createElement('img');
    img.height = 1;
    img.width = 1;
    img.style.display = 'none';
    img.src = `https://www.facebook.com/tr?id=${this.metaPixelId}&ev=PageView&noscript=1`;

    noscriptContainer.appendChild(img);
    document.body.appendChild(noscriptContainer);

    this.isMetaPixelInitialized = true;
  }

  removeMetaPixel() {
    const script = document.getElementById('meta-pixel-script');
    if (script) {
      script.remove();
    }

    const noscriptContainer = document.getElementById('meta-pixel-noscript');
    if (noscriptContainer) {
      noscriptContainer.remove();
    }

    this.isMetaPixelInitialized = false;
  }

  trackPageView(url: string) {
    if (this.isMetaPixelInitialized && typeof fbq === 'function') {
      fbq('track', 'PageView', { page_path: url });
    }
  }
}
