<nav class="navbar">
  <div
    class="container header-container"
    [class.mobile-logo-only]="!showBackButton"
  >
    @if (showBackButton) {
      <app-back-button></app-back-button>
    }
    <a class="navbar-brand" routerLink="/">
      @if (!showBackButton) {
        <img
          class="media-object header-logo desktop-only"
          [src]="userTheme === 'dark' ? env.logoDarkAsset : env.logoAsset"
          alt="eventpage.ai - Logo"
        />
      }
      <img
        class="media-object header-logo mobile-only"
        [src]="userTheme === 'dark' ? env.iconDarkAsset : env.iconAsset"
        alt="eventpage.ai - Logo"
      />
    </a>
    @if (showBackButton) {
      <div class="flex-placeholder"></div>
    }
  </div>
</nav>
