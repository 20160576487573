<mat-sidenav-container
  #container
  [ngClass]="
    isPublicRoute && !isTemplatesOverviewPage
      ? 'white-background'
      : 'internal-background'
  "
  [class.create-event-page]="isCreateEventPage"
>
  @if (!isPageWithoutSideItems) {
    <mat-sidenav
      #sidenav
      class="internal-mat-sidenav"
      [class.customer-view]="isCustomer"
      [class.closed]="!sidenav.opened"
      [mode]="isSideMode ? 'side' : 'over'"
      position="start"
    >
      <app-internal-sidenav
        [selectedCommunity]="selectedCommunity"
        [routes]="internalRoutes"
        [loggedUser]="user"
        [isOverMode]="!isSideMode"
        [isCustomerView]="isCustomer"
        (openReferralInviteDialog)="openReferralInviteDialog()"
      >
      </app-internal-sidenav>
    </mat-sidenav>
  }

  <mat-sidenav-content #sidenavContent>
    @if (
      isPageWithGenericHeader &&
      !isCreateEventPage &&
      !(isPageResetPassword && selectedCommunity)
    ) {
      <app-header></app-header>
    }
    @if (
      isAdmin &&
      !isPublicRoute &&
      !isEventPage &&
      !isCampaignEventVotingPage &&
      !isLandingPage &&
      !isSpacePage &&
      !isSpaceBookingPage
    ) {
      <app-internal-header></app-internal-header>
    }
    @if (
      isCustomer &&
      !isPublicRoute &&
      !isEventPage &&
      !isCampaignEventVotingPage &&
      !isLandingPage &&
      !isTermsOfUsePage &&
      !isDesignTemplatePreviewPage &&
      !isCommunityLandingPage &&
      !this.isPageResetPassword &&
      !isSpacePage &&
      !isSpaceBookingPage
    ) {
      <app-internal-customer-header
        [loggedUser]="user"
        [routes]="internalRoutes"
        [isLowerZIndexPage]="isLinkBioPage"
        [selectedCommunity]="selectedCommunity"
        (openReferralInviteDialog)="openReferralInviteDialog()"
      ></app-internal-customer-header>
    }

    <div
      class="sticky-footer theme-background"
      [class.community-theme]="isPageResetPassword && selectedCommunity"
      [class.internal]="isAdminOrCustomer && !isPublicRoute"
      [class.internal-no-header]="
        (isDesktop && isAdminOrCustomer && !isPublicRoute) ||
        isEventPage ||
        isCampaignEventVotingPage ||
        isDesignTemplatePreviewPage ||
        isLinkBioPage ||
        isLandingPage ||
        isTermsOfUsePage ||
        isEventPayment ||
        isCommunityLandingPage ||
        isCommunityPage ||
        isSpacePage ||
        isSpaceBookingPage
      "
      [class.public]="!isCustomer && !isAdmin"
      [class.pt-0]="isPublicDesignTemplatesRoute"
      [class.h-100]="isTemplatesOverviewPage"
      style="flex: 1 0 auto !important"
    >
      <router-outlet></router-outlet>
    </div>

    @if (isPageWithGenericFooter && !selectedCommunity) {
      <app-footer class="app-footer"></app-footer>
    }
  </mat-sidenav-content>
</mat-sidenav-container>
