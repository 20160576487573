import { isPlatformServer } from '@angular/common';

export class CookieUtils {
  static getCookie(name: string): string | null {
    if (!document.cookie) {
      return null;
    }
    const match = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]+)'),
    );
    return match ? match[2] : null;
  }

  static hasGtmConsent(platformId?: Object): boolean {
    if (platformId && isPlatformServer(platformId)) {
      return false;
    }

    const customWindow = window as any;

    if (
      typeof customWindow.CCM == 'object' &&
      customWindow.CCM.acceptedEmbeddings
    ) {
      if (customWindow.CCM.fullConsentGiven) {
        return true;
      }

      const acceptedEmbeddings = customWindow.CCM.acceptedEmbeddings;

      return acceptedEmbeddings.some(
        (embedding: any) => embedding.name === 'Google Analytics',
      );
    }

    return false;
  }

  static hasPixelConsent(platformId?: Object): boolean {
    if (platformId && isPlatformServer(platformId)) {
      return false;
    }

    const customWindow = window as any;

    if (
      typeof customWindow.CCM == 'object' &&
      customWindow.CCM.acceptedEmbeddings
    ) {
      if (customWindow.CCM.fullConsentGiven) {
        return true;
      }

      const acceptedEmbeddings = customWindow.CCM.acceptedEmbeddings;

      return acceptedEmbeddings.some(
        (embedding: any) => embedding.name === 'Facebook Pixel',
      );
    }

    return false;
  }

  static isCCMInit(platformId?: Object): boolean {
    if (platformId && isPlatformServer(platformId)) {
      return false;
    }

    const customWindow = window as any;

    if (
      typeof customWindow.CCM == 'object' &&
      customWindow.CCM.acceptedEmbeddings
    ) {
      return true;
    }

    return false;
  }
}
